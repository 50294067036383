`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`
`import hideRepeatRecordPreviewBtn from 'javascripts/components/shared/hide_repeat_record_preview_btn'`

OkDeskTabs = do ->
  init: ->
    # Init once for Turbolinks
    that = @
    tabs = '.okdesk-tabs--item'

    @inited ||= do =>
      $(document).on 'click', tabs, (e) ->
        tab = $ e.currentTarget
        return true if tab.hasClass 'active'

        tab_block = tab.closest('.okdesk-tabs')
        tab_id    = tab.attr('tab-id')

        content_item = tab_block.find(".okdesk-tabs--content-item[tab-id='#{ tab_id }']")
        return true if content_item.length is 0

        tab_block.height(tab_block.outerHeight())
        tab_block.find('.okdesk-tabs--content-item').hide()
        content_item.show
          duration: 1
          done: ->
            tab_block.height('auto')

        tab_block.find('.okdesk-tabs--item').removeClass 'active'
        tab.addClass 'active'

        that.init_tab_content(tab, content_item.attr('tab-id'), tab_block) if tab.data('content-remote') is true

    tab_from_hash =
      $("#{tabs}[tab-id='#{window.location.hash.replace('#', '')}']")
    if tab_from_hash.length > 0
      tab_from_hash.trigger('click')
    else
      $(tabs).first().trigger('click')

  init_tab_content: (tab, content_container, tab_block) ->
    unless tab.attr('data-loaded')
      tab.attr('data-loaded', true)
      $.ajax
        url: tab.data('content-link'),
        type: 'GET',
        dataType: 'json',
        data: {
          tab_id: content_container,
        }
        success: (data, status, xhr) ->
          perform_json_response(data)
          # Async page change is necessary because of required functions call on changed DOM after
          # perform_json_response execution. Example - comments spoliers logic on issue card.
          setTimeout(() ->
            $(document).trigger('page:change')
            tab.trigger('okdesk-tab:loaded')
            hideRepeatRecordPreviewBtn(tab_block)
          , 1)

`export default OkDeskTabs`
