const init_file_uploader_callback = () => {
  $(document).off('change', '@tackable-file-field').on('change', '@tackable-file-field', (e) => {
    const fileInput = $(e.target);
    const { files } = e.target;

    validate_uploaded_files_size(fileInput, files);
  });
};

const validate_uploaded_files_size = (fileInput, files) => {
  const fileModal = fileInput.closest('.file-upload-modal');
  const fileNames = [];
  let maxFileSize = 0;

  Array.from(files).forEach((file) => {
    fileNames.push(file.name);
    maxFileSize = Math.max(maxFileSize, file.size / 1024);
  });

  const errorMessageAttribute = fileModal.find('.attachment-error-message');

  // it's necessary to make some change in JS after changing contstant
  // in order to force assets compiltation
  // current limit - 75 MB
  const defaultSize = gon.maxAttachmentFileSize;

  // file size in kilobytes
  if (maxFileSize > defaultSize * 1024) {
    const message = errorMessageAttribute.attr('data-message');
    errorMessageAttribute.html(`<p>${message}</p>`);
    fileModal.find("button[type='submit']").prop('disabled', true);
  } else {
    errorMessageAttribute.html('');
    fileModal.find("button[type='submit']").prop('disabled', false);
  }

  const trackInput = fileInput.data('track-input');
  if (trackInput) {
    $(`#${trackInput}`).val(fileNames.join(', '));
  }
};

export { init_file_uploader_callback, validate_uploaded_files_size };
