`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`
`import ModalDialog from 'javascripts/vendors/modal_dialog'`
`import { validate_uploaded_files_size } from 'javascripts/main/initializer_functions'`

FileUploader = do ->
  droppedFiles = []

  init: (files, resourceId)->
    # Init once for Turbolinks
    that         = @
    droppedFiles = files
    uploadModal  = $("@file-upload-modal-dialog-#{resourceId}")
    form         = uploadModal.find('form')
    fileInput    = form.find("input[type='file']")

    form.find('@attachment-file-name').val(@get_files_name())

    validate_uploaded_files_size(fileInput, droppedFiles)

    form.off('submit').on 'submit', (e) ->
      submitBtn     = form.find("button[type='submit']")
      submitBtnText = submitBtn.text()

      submitBtn.text(submitBtn.data('disable-with')).attr('disabled', 'disabled')

      $.ajax
        url: form.attr('action'),
        type: form.attr('method'),
        data: that.get_form_data(form),
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        success: (data, status, xhr) ->
          if data? && !data.error?
            perform_json_response(data)
            ModalDialog.list["@#{uploadModal.data('role')}"].hide()
        complete: ->
          submitBtn.data('clicked', false)
          submitBtn.text(submitBtnText).removeAttr('disabled')
      return false

    fileInput.on 'change', ->
      form.off('submit')

  get_form_data: (form) ->
    ajaxData  = new FormData(form.get(0))
    inputName = form.find("input[type='file']").attr('name')

    $.each droppedFiles, (i, file) ->
      ajaxData.append(inputName, file)

    return ajaxData

  get_files_name: ->
    fileNames = []
    $.each droppedFiles, (i, file) ->
      fileNames.push(file.name)

    return fileNames.join(', ')

`export default FileUploader`
