`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`
`import initialize_breadcrumbs from 'javascripts/main/breadcrumbs'`
`import resizeIframeContent from 'javascripts/components/helpers/resize_iframe_content'`
`import ScrollableContent from 'javascripts/vendors/scrollable_content'`
`import Turbolinks from 'javascripts/turbolinks'`

KnowledgeBaseNavigation = do ->
  contentResized: true
  accuracy: 0.3

  init: ->
    return if $('.knowledge-sidebar').length == 0

    that = @
    that.resize_content_blocks()

    @inited ||= do =>
      $(window).on 'resize', ->
        return unless that.contentResized
        that.contentResized = false
        # Timeout needs to prevent events stacking and multiple block width recalculation
        setTimeout ( ->
          that.resize_content_blocks()
          that.contentResized = true
        ), 500


      $(document).on 'click', '.knowledge-sidebar-menu .knowledge-menu-item .show-children-btn', (e) ->
        e.preventDefault()
        element = $(e.target).closest('.knowledge-menu-item').toggleClass('visible')

      $(document).on 'click', '@knowledge-base-section-url, @knowledge-base-article-url', (e) ->
        menu           = $('.knowledge-sidebar-menu')
        element        = $(@)
        menu_item_text = menu.find("[data-role='#{element.attr('data-role')}'][data-id='#{element.attr('data-id')}']")
        menu_element   = menu_item_text.closest('.knowledge-menu-item')

        if menu_element.hasClass('selected')
          menu_element.toggleClass('visible')
          that.resize_content_blocks()
        else
          menu.find('.knowledge-menu-item').removeClass('selected')
          menu_element.addClass('selected')
          menu_item_text.parents('.knowledge-menu-item').addClass('visible')

          $('.loading-wrapper').addClass('loading-data')
          $('.loading-wrapper .loading-indicator').show()
          url = element.attr('data-url')

          $.ajax
            url: url,
            type: 'GET',
            dataType: 'json',
            success: (data) ->
              that.unload_article_ckeditor()
              that.unload_section_ckeditor()
              perform_json_response(data)
              $('.loading-wrapper').removeClass('loading-data')
              $('.loading-wrapper .loading-indicator').hide()
              $('@section-attachments-tab').click()
              history.pushState({ url: url, type: 'json' }, null, url)
              that.resize_content_blocks()
              initialize_breadcrumbs()
              resizeIframeContent('@knowledge-base-article-content')
              resizeIframeContent('@knowledge-base-section-description')
              document.title = data.new_title if data.new_title

      $(document).on 'ajax:success', '@knowledge-base-search-form', (e, data, status, xhr) ->
        $('.knowledge-sidebar-menu.scrollable-content').mCustomScrollbar('destroy')
        perform_json_response(data)
        ScrollableContent.init()

      $(document).on 'click', '.knowledge-search-reset .reset-filter-btn', (e) ->
        e.preventDefault()
        reset_btn   = $(e.target)
        search_form = reset_btn.closest('@knowledge-base-search-form')

        search_form.find('#search').val('')
        search_form.submit()
        reset_btn.addClass('disabled')

      $(document).on 'change', '@knowledge-base-search-form #search', (e) ->
        reset_btn = $(@).closest('@knowledge-base-search-form').find('.knowledge-search-reset .reset-filter-btn')
        reset_btn.removeClass('disabled') if $(@).val().length > 0

      $(document).on 'click', '@knowledge-base-view-toggler', (e) ->
        button = $(e.target)
        return if button.hasClass('active') || !button.data('type')

        $.ajax
          url: $(e.currentTarget).data('toggle-url'),
          type: 'PATCH',
          data: { user: { view_type: button.data('type') } },
          dataType: 'json',
          success: (data) ->
            perform_json_response(data)
            selectedItem = $('.knowledge-sidebar-menu .knowledge-menu-item.selected')

            # Reload page on KB root page when no selected sections
            if selectedItem.length == 0
              $('.loading-wrapper').addClass('loading-data')
              $('.loading-wrapper .loading-indicator').show()
              return Turbolinks.visit(window.location.toString(), { action: 'replace' })

            selectedRow = $('.knowledge-sidebar-menu .knowledge-menu-item.selected > .knowledge-menu-item-row')
            selectedItem.removeClass('selected')
            selectedRow.find('@knowledge-base-section-url').click()
            selectedItem.addClass('selected')


  resize_content_blocks: ->
    knowledges_holder = $('.knowledges-content-block')
    return unless knowledges_holder.length

    knowledge_blocks = knowledges_holder.find('.knowledge-block')

    if knowledge_blocks.attr('data-width')
      knowledge_block_width = parseInt(knowledge_blocks.attr('data-width'))
      knowledge_block_distance = parseInt(knowledge_blocks.attr('data-distance'))
    else
      knowledge_block_width = knowledge_blocks.width()
      knowledge_block_border_width = knowledge_blocks.outerWidth() - knowledge_block_width
      knowledge_block_distance = parseInt(knowledge_blocks.css('margin-right')) + knowledge_block_border_width

      knowledge_blocks.attr('data-width', knowledge_block_width)
      knowledge_blocks.attr('data-distance', knowledge_block_distance)

    knowledge_holder_width = knowledges_holder.width()
    blocks_inline_total = parseInt(knowledge_holder_width / (knowledge_block_width + knowledge_block_distance))
    blocks_distances = blocks_inline_total * knowledge_block_distance

    blocks_width = (knowledge_holder_width - blocks_distances) / blocks_inline_total - @accuracy

    knowledge_blocks.css('width', "#{blocks_width}px")

  unload_article_ckeditor: ->
    $('@knowledge-base-article-edit-form .ckeditor_textarea').each ->
      instance_id = $(@).attr('id')
      instance = CKEDITOR.instances[instance_id]
      if typeof(instance) != 'undefined'
        instance.destroy()

  unload_section_ckeditor: ->
    $('@knowledge-base-section-edit-form .ckeditor_textarea').each ->
      instance_id = $(@).attr('id')
      instance = CKEDITOR.instances[instance_id]
      if typeof(instance) != 'undefined'
        instance.destroy()

`export default KnowledgeBaseNavigation`
