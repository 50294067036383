`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`

$(document).on 'click', '@download-files', (e) ->
  e.currentTarget.classList.add('disabled')
  localStorage.removeItem('download-tab-key')
  window.download_tab_key = Math.random().toString(36)
  localStorage.setItem('download-tab-key', window.download_tab_key)

$(document).on 'ajax:success', '@download-files', (e, data, status, xhr) ->
  perform_json_response(data)

$(document).on 'click', '@rotate-button', (e) ->
  e.preventDefault()
  direction    = e.target.dataset.direction
  rotateTarget = $(e.target).closest('@attachment-preview-modal-dialog').find('@rotate-target')
  parent       = rotateTarget.parent()
  width        = rotateTarget.width()
  rotateDiff = 90
  rotateDiff = -rotateDiff if direction == 'left'
  angle = (rotateTarget.data('angle') + rotateDiff) % 360

  rotateTarget.attr('style', "transform: rotate(#{angle}deg)")
  rotateTarget.data('angle', angle)

  if Math.abs(angle) == 90 || Math.abs(angle) == 270
    rotateTarget.addClass('rotated')
    parent.height(width) if parent.height() < width
  else
    rotateTarget.removeClass('rotated')
    parent.removeAttr('style') if parent.attr('style')
