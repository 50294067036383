`import escape_html from 'javascripts/components/shared/escape_html'`

FlashBlock = do ->
  init: ->
    $('@flash-container').off('click', '@notification-line-close').on 'click', '@notification-line-close', (e) ->
      link = $ e.currentTarget
      link.parents('@notification-line').slideUp()
    @slide_up($('.notification-line'))

  show_error: (text) ->
    @render_flash_with_holder('alert', text)

  show_notice: (text) ->
    @render_flash_with_holder('notice', text)

  render_flash_with_holder: (type, text) ->
    flash_id = 'flash-template-' + $('@flash-container @notification-line').length
    flash_html = @flash_template(flash_id, type, text)
    $('@flash-container').html(flash_html)
    $('#' + flash_id).show()
    @slide_up($('#' + flash_id))

  show_message: (flashMessage) ->
    @init()

    if flashMessage && flashMessage.type == 'notice'
      @show_notice(flashMessage.text)
    else
      @show_error(flashMessage.text)

  flash_template: (flash_id, type_text, text_html) ->
    """
    <div class="notification-line with-correct-words-breaking #{ type_text }" data-role="notification-line" id="#{ flash_id }">
      <a data-role="notification-line-close " class="close-#{ type_text }" href="javascript:void(0)"></a>
      <span>#{ escape_html(text_html) }</span>
    </div>
    """

  slide_up: (selector) ->
    setTimeout (-> selector.slideUp()), 10000

`export default FlashBlock`
