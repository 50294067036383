`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`
`import ModalDialog from 'javascripts/vendors/modal_dialog'`
`import resizeIframeContent from 'javascripts/components/helpers/resize_iframe_content'`
`import initialize_breadcrumbs from 'javascripts/main/breadcrumbs'`

$(document).on 'ajax:success', '@knowledge-base-article-btn', (e, data, status, xhr) ->
  old_modal = ModalDialog.list['@knowledge-base-article-modal-dialog']
  old_modal.modal.remove() if old_modal
  perform_json_response(data)
  if data? && !data.error?
    ModalDialog.new('@knowledge-base-article-modal-dialog')
    ModalDialog.list['@knowledge-base-article-modal-dialog'].show()

$(document).on 'ajax:success', '@knowledge-base-article-edit-form', (e, data, status, xhr) ->
  perform_json_response(data)
  $('@knowledge-base-article-edit-form .reset-btn').click() if data? && !data.error?
  resizeIframeContent('@knowledge-base-article-content')

$(document).on 'ajax:success', '@delete-knowledge-base-article-btn', (e, data, status, xhr) ->
  perform_json_response(data)

$(document).on 'ajax:success', '@knowledge-base-article-form', (e, data, status, xhr) ->
  $('.knowledge-menu-item').removeClass('selected')
  perform_json_response(data)
  if data? && !data.error?
    ModalDialog.list['@knowledge-base-article-modal-dialog'].hide()
    history.pushState({ url: data.url, type: 'json' }, null, data.url)
    initialize_breadcrumbs()
