function spoilerToggleHandler(target) {
  $(target).children().first().toggleClass('ckeditor-spoiler-show-icon')
    .toggleClass('ckeditor-spoiler-hide-icon');
  $(target).parent().children().last()
    .toggle();
}

$(document).on('click', 'div.ckeditor-spoiler-title', (e) => {
  const { currentTarget } = e;
  spoilerToggleHandler(currentTarget);
});

CKEDITOR.on('instanceReady', (event) => {
  const { editor } = event;
  const { document } = editor;

  editor.on('afterInsertHtml', () => {
    const divs = document.getElementsByTag('div');
    const len = divs.count();
    let element;
    for (let i = 0; i < len; i += 1) {
      element = divs.getItem(i);
      if (element.hasClass('ckeditor-spoiler-toggle') && !element.hasListeners('click')) {
        element.on('click', (e) => {
          spoilerToggleHandler(e.sender.getParent().$);
        });
      }
    }
  });
});
