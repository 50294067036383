ScrollableContent = do ->
  init: ->
    return if $('.scrollable-content').length == 0 && $('.scrollable-columner').length == 0

    that = @
    that.resize_content()

    @inited ||= do =>
      $(window).on 'resize', ->
        that.resize_content()
      new ResizeObserver(-> that.resize_content()).observe($('header').get(0))

  resize_content: ->
    # fix for settings pages wich don't need whole page scroll
    # TODO: remove it after release of new settings pages
    if $('.scrollable-content .disable-page-scroll').length == 0
      headersHeight = $('header').height() + $('footer').height() + $('.main-breadcrumbs').height()
      $('.scrollable-content').css('max-height', $(window).height() - headersHeight)
      $('.scrollable-content').mCustomScrollbar({
        scrollInertia: 0,
        theme: 'minimal-dark',
        keyboard: { enable: false },
        mouseWheel: {
          disableOver: ["select", "option", "keygen", "datalist", "textarea", ".multilevel-select--ul",
                        ".custom-select--ul", '[data-role="access-rights-table-react-component"]',
                        '.disable-mCustomScrollbar'],
          scrollAmount: 40
        }
      })
    else
      $('.scrollable-content').mCustomScrollbar('destroy')

    @resize_columner()

  resize_columner: ->
    externalContentHeight = $('header').height() + $('footer').height() + $('.sidebar_header').outerHeight(true)
    $('.scrollable-columner').css('max-height', $(window).height() - externalContentHeight)
    $('.scrollable-columner').mCustomScrollbar({
      scrollInertia: 0,
      theme: 'minimal-dark',
      keyboard:{ enable: false }
    })

`export default ScrollableContent`
