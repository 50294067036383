import perform_json_response from 'javascripts/components/helpers/perform_json_response';
import ModalDialog from 'javascripts/vendors/modal_dialog';
import CustomCheckbox from 'javascripts/vendors/custom_checkbox';
import ScrollableContent from 'javascripts/vendors/scrollable_content';
import resizeIframeContent from 'javascripts/components/helpers/resize_iframe_content';

const checkKnowledgeBaseAccessFields = (checkbox) => {
  const accessRightsInputs = $('[data-role="knowledge-base-access-rights-fields"]').find('select, input');

  if (checkbox.hasClass('false')) {
    $('[data-role="knowledge-base-access-rights-fields"').show();
    accessRightsInputs.prop('disabled', false);
  } else {
    $('[data-role="knowledge-base-access-rights-fields"]').hide();
    accessRightsInputs.prop('disabled', 'disabled');
  }
};

const checkKnowledgeBaseAccesToCategoryField = (checkbox) => {
  const accessRightsInput = $('[data-role="access-to-show-for-client-categories"]').find('select, input');

  if (checkbox.hasClass('false')) {
    $('[data-role="access-to-show-for-client-categories"]').hide();
    accessRightsInput.prop('disabled', 'disabled');
  } else {
    $('[data-role="access-to-show-for-client-categories"]').show();
    accessRightsInput.prop('disabled', false);
  }
};

const checkKnowledgeBaseAccessForClientsField = (checkbox) => {
  const accessRightsInput = $('[data-role="access-to-show-for-clients"]').find('select, input');
  const isCheckBoxUnChecked = checkbox.hasClass('false');

  $('[data-role="access-to-show-for-clients"]').toggleClass('hidden', isCheckBoxUnChecked);
  if (isCheckBoxUnChecked) {
    accessRightsInput.prop('disabled', 'disabled');
  } else {
    accessRightsInput.prop('disabled', false);
  }
};

$(document).on('ajax:success', '[data-role="knowledge-base-section-btn"]', (e, data) => {
  const oldModal = ModalDialog.list['[data-role="knowledge-base-section-modal-dialog"]'];
  if (oldModal) { oldModal.modal.remove(); }
  perform_json_response(data);
  if (data != null) {
    ModalDialog.new('[data-role="knowledge-base-section-modal-dialog"]');
    ModalDialog.list['[data-role="knowledge-base-section-modal-dialog"]'].show();
    const checkbox = $('[data-role="knowledge-base-section-access-rights-inheritance"] .checkbox');
    checkKnowledgeBaseAccessFields(checkbox);
    const checkboxWithDetailsForCategory = $('[data-role="more-details-for-client-categories-checkbox"] .custom-checkbox .checkbox');
    checkKnowledgeBaseAccesToCategoryField(checkboxWithDetailsForCategory);
    const checkboxWithDetailsForClients = $('[data-role="more-details-for-clients-checkbox"] .custom-checkbox .checkbox');
    checkKnowledgeBaseAccessForClientsField(checkboxWithDetailsForClients);
  }
});

$(document).on('ajax:success', '[data-role="delete-knowledge-base-section-btn"]', (e, data) => perform_json_response(data));

$(document).on('ajax:success', '[data-role="knowledge-base-section-edit-form"]', (e, data) => {
  perform_json_response(data);
  if (data && !data.error) {
    $('@knowledge-base-section-edit-form .reset-btn').click();
  }
  resizeIframeContent('@knowledge-base-section-description ');
});

$(document).on('change', '[data-role="knowledge-base-section-access-rights-inheritance"] input[type="checkbox"]', (e) => {
  const checkbox = $(e.target).closest('.custom-checkbox').find('.checkbox');
  checkKnowledgeBaseAccessFields(checkbox);
});

$(document).on('change', '#knowledge_base_section_parent_id', (e) => {
  const input = $(e.target);
  const accessInputs = $('[data-role="knowledge-base-access-rights-fields"]');
  if (input.val().length === 0) {
    accessInputs.show();
    accessInputs.find('select, input').prop('disabled', false);
    $('[data-role="knowledge-base-section-access-rights-inheritance"]').hide();
  } else {
    $('[data-role="knowledge-base-section-access-rights-inheritance"]').show();
    const checkbox = $('[data-role="knowledge-base-section-access-rights-inheritance"] .checkbox');
    checkKnowledgeBaseAccessFields(checkbox);
  }
});

$(document).on('ajax:success', '[data-role="knowledge-base-section-form"]', (e, data) => {
  $('.knowledge-sidebar-menu.scrollable-content').mCustomScrollbar('destroy');
  perform_json_response(data);
  ScrollableContent.init();
  ModalDialog.list['[data-role="knowledge-base-section-modal-dialog"]'].hide();
  $('[data-role="section-attachments-tab"]').click();
});

$(document).on('change', '#knowledge_base_section_access_to_show', (e) => {
  const accessRightsInput = $('[data-role="access-to-show-for-client-categories"]').find('select, input');
  const target = $(e.target);
  const checkboxField = $('[data-role="more-details-for-client-categories-checkbox"]');
  const clientsAccessRightsInput = $('[data-role="access-to-show-for-clients"]').find('select, input');
  const clientsCheckboxField = $('[data-role="more-details-for-clients-checkbox"]');

  if (target.val() && target.val().includes('contact') && !target.val().includes('is_public')) {
    checkboxField.show();
    accessRightsInput.prop('disabled', false);

    clientsCheckboxField.toggleClass('hidden', false);
    clientsAccessRightsInput.prop('disabled', false);
  } else {
    CustomCheckbox.setValue(checkboxField, false);
    checkboxField.hide();
    $('[data-role="access-to-show-for-client-categories"]').hide();
    accessRightsInput.prop('disabled', 'disabled');

    CustomCheckbox.setValue(clientsCheckboxField, false);
    clientsCheckboxField.toggleClass('hidden', true);
    $('[data-role="access-to-show-for-clients"]').toggleClass('hidden', true);
    clientsAccessRightsInput.prop('disabled', 'disabled');
  }
});

$(document).on('change', '[data-role="more-details-for-client-categories-checkbox"] .custom-checkbox', (e) => {
  const checkbox = $(e.currentTarget).find('.checkbox');
  checkKnowledgeBaseAccesToCategoryField(checkbox);
});

$(document).on('change', '[data-role="more-details-for-clients-checkbox"] .custom-checkbox', (e) => {
  const checkbox = $(e.currentTarget).find('.checkbox');
  checkKnowledgeBaseAccessForClientsField(checkbox);
});

$(document).on('click', '[data-role="knowledge-base-open-list-btns"]', () => {
  const verticalGroupButtons = $('.vertical-buttons-group');
  verticalGroupButtons.toggleClass('hidden');
  return false;
});

$(document).on('click', 'body, a', (e) => {
  if ($(e.target).data('role') !== 'knowledge-base-open-list-btns') {
    const verticalGroupButtons = $('.vertical-buttons-group');
    verticalGroupButtons.addClass('hidden');
  }
});
