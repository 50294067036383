`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`
`import ModalDialog from 'javascripts/vendors/modal_dialog'`
`import FileUploader from 'javascripts/components/shared/file_uploader'`

init_change_attachments_callback = ->
  $('@attachments-upload-form, @printed-form-attachments-upload-form').off('ajax:success').on 'ajax:success', (e, data, status, xhr) ->
    e.preventDefault()
    form = $(@)
    perform_json_response(data)
    form.find('.submit-btn').data('clicked', false)
    if data? && !data.error?
      ModalDialog.list["@#{form.closest('.modal-dialog').data('role')}"].hide()
      init_change_attachments_callback()

$(document).on 'ajax:success', '@remove-attachment-link', (e, data, status, xhr) ->
  e.preventDefault()
  if data? && !data.error?
    perform_json_response(data)
    if data.with_check_list_item_dependency
      ModalDialog.new('@check-list-dependency-message')
      ModalDialog.list['@check-list-dependency-message'].show()
  $('@resource-attachments-list').removeClass('loading-content')

$(document).on 'confirm:complete', '@remove-attachment-link', (e, answer) ->
  $('@resource-attachments-list').addClass('loading-content') if answer

$(document).on 'ajax:success', '@edit-attachment-form', (e, data, status, xhr) ->
  e.preventDefault()
  if data? && !data.error?
    perform_json_response(data)

$(document).on 'ajax:success', '@upload-file-link', (e, data, status, xhr) ->
  e.preventDefault()
  show_attachment_modal_dialog(data, '@' + $(@).data('target'))

$(document).on 'click', '@attachments-upload-form .submit-btn, @printed-form-attachments-upload-form .submit-btn', (e) ->
  e.preventDefault()
  unless $(@).data('clicked')
    $(@).data('clicked', true)
    $(@).closest('form').submit()
  return false

# ============================DRAG AND DROP====================================

$(document).on 'drag dragstart dragend dragover dragenter dragleave drop', '.drop-area', (e) ->
  e.preventDefault()
  e.stopPropagation()

$(document).on 'ready page:load', ->
  $('body').on 'dragbetterenter', ->
    exceptedElementIsDragged = $('.except-attachment-drag-and-drop, ' +
                                 '[data-role="nested_issue-workspace-table-view"]:visible').length > 0
    issueStatusFormIsClosed = $('@change-status-modal .modal-dialog-window:visible').length == 0
    issueHistoryEventsHidden = $('.okdesk-tabs--item.history-events.active').length == 0

    if !exceptedElementIsDragged && issueStatusFormIsClosed && issueHistoryEventsHidden
      filesTab = $('@attachments-tab:visible')
      if filesTab.length > 0 && !filesTab.hasClass('active')
        filesTab.click()
        filesTab.on 'okdesk-tab:loaded', ->
          $('.drop-area.enabled:visible').addClass('active')

      visibleDropArea = $('.drop-area.enabled:visible')
      visibleDropArea.addClass('active') if visibleDropArea.hasClass('enabled')

  $('body').on 'dragbetterleave', (e) ->
    $('.drop-area.enabled:visible').removeClass('active')

$(document).on 'drop', '.drop-area', (e) ->
  dropArea = $('.drop-area.enabled:visible')
  return false if dropArea.length == 0
  droppedFiles = e.originalEvent.dataTransfer.files
  $.ajax
    url: dropArea.data('url'),
    type: 'GET'
    dataType: 'json'
    success: (data, status, xhr) ->
      show_attachment_modal_dialog(data, "@file-upload-modal-dialog-#{dropArea.data('id')}")
      FileUploader.init(droppedFiles, dropArea.data('id'))

# =============================================================================

show_attachment_modal_dialog = (data, target) ->
  if data? && !data.error?
    perform_json_response(data)
    ModalDialog.new(target)
    init_change_attachments_callback()
    ModalDialog.list[target].show()

$(document).on 'ajax:success', '@attachment-preview-btn', (e, data, status, xhr) ->
  perform_json_response(data)
  if data && !data.error?
    ModalDialog.new('@attachment-preview-modal-dialog')
    ModalDialog.list['@attachment-preview-modal-dialog'].show()
